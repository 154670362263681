@import 'assets/scss/_colors';

.countdown-timer {
  display: flex;
  align-items: flex-start;
  color: $black;
  line-height: 1.25;

  &[data-text-upper=true] {
    text-transform: uppercase;
  }

  &[data-white-color=true] {
    color: $white;
  }

  .countdown-timer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5.4rem;
    .countdown-time {
      font-size: 3.8rem;
      font-weight: bold;
    }
    .countdown-hint {
      font-size: 1.3rem;
    }
  }
  .countdown-timer-delimiter-item {
    margin: 0px 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.8rem;
  }
}
