@import-sanitize;
@import 'assets/scss/_fonts';
@import 'assets/scss/_variables';

html {
  font-size: 62.5%;
  min-width: $container-width;
  font-family: 'Montserrat', sans-serif;
}

body {

  // reset antd
  font-size: 62.5%;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
  font-variant: normal;
  color: rgb(0, 0, 0);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  // reset antd
  font-weight: bold;
  color: inherit;
}

ul,
ol {
  margin: 0;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}
