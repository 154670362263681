@import 'assets/scss/_colors.scss';
.container {
  margin-top: 39px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  .arrow {
  }
  .numbers {
    margin: 0 43.1px;
    > * {
      &:not(:last-child) {
        margin-right: 26px;
      }
    }
  }

  .left {
  }
  .right {
  }
}
