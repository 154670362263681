.container {
  cursor: auto;
  font-size: 14px;
  line-height: 25px;
  .title {
    font-weight: 600;
  }

  .content {
    & > p,
    div {
      margin: 0;
    }
    & > table {
      & th,
      td {
        font-size: 14px;
        border: 1px solid #cccccc;
        width: 344px;
        text-align: center;
      }

      th {
        font-weight: 600;
      }

      td {
        font-weight: 300;
      }
    }
  }
}
