@import 'assets/scss/_colors';

.lottery-history-header {
  padding: 41px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: $white;
  .lottery-history-header-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .lottery-history-winner-number {
      line-height: 1.2;
      font-size: 2rem;
      .lottery-history-winner-number-highlight {
        margin-left: 10px;
        font-weight: bold;
        &:after {
          // 解決文字隱藏時的佔位問題
          display: block;
          content: attr(data-number-placeholder);
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }

    .lottery-history-lottery-number {
      margin-top: 30px;
      display: flex;
      align-items: center;
      font-size: 2.3rem;
      font-weight: 600;
      .lottery-history-lottery-number-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 5rem;
        border: 2px solid $primary-blue-heavy;
        border-radius: 50%;

        & + {
          .lottery-history-lottery-number-item {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .lottery-history-header-right {
    padding: 3.6rem 0px 3.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38.7rem;
    border-radius: 16px;
    background-color: $primary-blue-heavy;
    .lottery-history-next-lottery-title {
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.25;
      color: $white;
    }
    .lottery-history-next-lottery-countdown-timer {
      margin-top: 13px;
    }
  }
}