@import 'assets/scss/_colors.scss';
.container {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  background: $primary-blue;
  position: relative;
  font-size: 24px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  margin: 2px;
  border-radius: 50%;
}

.content {
  z-index: 1;
  font-weight: 600;
  line-height: 28px;
  font-size: 23px;
}
