@import 'assets/scss/_colors';
@import 'assets/scss/_variables';

.default-header-style {
  background-image: url('~assets/images/layouts/header-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  .default-header-container {
    margin: 0px auto;
    padding: 0px 56px;
    width: $container-width;
    .default-header-brand {
      padding: 3.2rem 0px 3.13rem;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 10.9rem;
      .default-header-logo {
        width: 15.5rem;
      }
    }

    // detail
    .default-header-detail {
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $white;
      .default-header-title {
        margin-top: 30px;
        line-height: 1.2;
        font-size: 6.5rem;
      }
      .default-header-winner-number {
        margin-top: 38px;
        line-height: 1.2;
        font-size: 1.6rem;
        display: flex;

        .default-header-winner-number-highlight {
          margin-left: 10px;
          font-weight: bold;
          &:after {
            // 解決文字隱藏時的佔位問題
            display: block;
            content: attr(data-number-placeholder);
            height: 0;
            overflow: hidden;
            visibility: hidden;
          }
        }
      }
      .default-header-lottery-number {
        margin-top: 20px;
        display: flex;
        align-items: center;
        font-size: 2.3rem;
        font-weight: 600;
        .default-header-lottery-number-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5rem;
          height: 5rem;
          border: 2px solid $white;
          border-radius: 50%;

          & + {
            .default-header-lottery-number-item {
              margin-left: 10px;
            }
          }
        }
      }
      .default-header-next-lottery {
        margin-top: 56px;
        padding: 3.6rem 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60rem;
        border-radius: 16px;
        background-color: $white;
        .default-header-next-lottery-title {
          line-height: 1.25;
          font-size: 1.8rem;
          font-weight: 500;
          color: $primary-blue-heavy;
        }
        .default-header-next-lottery-countdown-timer {
          margin-top: 13px;

        }
      }
    }
  }
}
