@import 'assets/scss/_colors.scss';

$content-width: 1085px;

.root {
  background: $home-bg-grey;
  font-family: 'Montserrat', sans-serif;
}

.container {
  cursor: auto;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 71px;
  margin-bottom: 222px;
}

.pageHeader {
  width: $content-width;
}

.pageSearch {
  margin-top: 27px;
  width: $content-width - 64px;
}

.pageTitle {
  line-height: 37px;
  margin-top: 48px;
  font-size: 30px;
  color: $primary-blue-heavy;
  font-weight: 700;
}

.results {
  display: inline-flex;
  flex-direction: column;
  max-width: $content-width;
  width: 100%;
  margin-top: 34px;
  > *:not(:last-child) {
    margin-bottom: 13px;
  }
}

.errorMessage {
  font-weight: 300;
  color: $primary-red;
}
