.lottery-history-search {
  .lottery-history-search-title {
    line-height: 1.2;
    font-size: 2rem;
    font-weight: 600;
  }

  .lottery-history-search-content {
    margin-top: 23px;
    display: flex;
    align-items: flex-end;

    > * {
      & + * {
        margin-left: 36px;
      }
    }

    .lottery-history-draw {
      width: 38.3rem;
      .lottery-history-draw-hint {
        font-size: 1.6rem;
      }
    }
    .lottery-history-date {
      .lottery-history-date-hint {
        font-size: 1.6rem;
      }
    }
  
    .lottery-history-search-button {
      min-width: 106px;
      min-height: 41px;
      border-radius: 4px;
      .lottery-history-search-button-content {
        font-size: 1.6rem;
        font-weight: 600;
      }
    }
  }

}