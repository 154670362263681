@import 'assets/scss/_colors';
@import 'assets/scss/_variables';

.default-footer-style {
  background-color: $primary-blue-heavy;
  .default-footer-container {
    margin: 0px auto;
    padding: 47px 0px 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $container-width;
    color: $white;
    .default-footer-logo {
      width: 19.8rem;
    }

    .default-footer-nav-menu {
      margin-top: 42.6px;
    }

    .default-footer-copyright {
      margin-top: 23px;
      font-size: 1.4rem;
      font-weight: lighter;
    }
  }
}
