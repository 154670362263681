@import 'assets/scss/_colors';

$width: 30rem;

.date-picker {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 20px;
  width: $width + 3.2rem;
  height: 4.1rem;
  line-height: 1;
  font-size: 1.6rem;
  border-radius: 4px;
  .date-picker-arrow {
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0.7rem 0.7rem 0px 0.7rem;
    border-color: $black transparent transparent transparent;
    border-radius: 0.7rem;
  }
  &[data-popover-visible=true] {
    .date-picker-arrow {
      border-width: 0px 0.7rem 0.7rem 0.7rem;
      border-color: transparent transparent $black transparent;
    }
  }

  &[data-selected-date=false] {
    color: rgba(0, 0, 0, .2);
  }
}

.calendar-container {
  width: $width;
  font-size: 1.2rem;
}