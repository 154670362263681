@import 'assets/scss/_colors.scss';
$baseRaduis: 8px;
$borderRadius: $baseRaduis 0 0 $baseRaduis;

.container {
  display: inline-flex;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  border-radius: 8px;
  background: white;
  position: relative;
  padding: 35px 38px;
  overflow-y: hidden;
  font-size: 1rem;
  box-shadow: -3px 0 0 -1px $primary-red;
  > *:not(:last-child):not(.mask) {
    margin-right: 95px;
  }
}

.mask {
  position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   margin-left: 2px;
  //   background: white;
  //   border-radius: $borderRadius;
}

.blockDraw,
.blockNumbers {
  z-index: 1;
  display: inline-flex;
  width: min-content;
  flex-direction: column;
}

.title {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 18px;
}

.content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.blockDraw {
  align-items: baseline;
  .title {
  }
  .content {
    line-height: 28px;
    font-size: 23px;
    height: 50px;
    font-weight: 600;
  }
  &::after {
    content: '202004298043';
    line-height: 28px;
    font-size: 23px;
    height: 0;
    opacity: 0;
    font-weight: 600;
  }
}

.blockNumbers {
  .title {
  }
  .content {
    > *:not(:last-child):not(.mask) {
      margin-right: 12px;
    }
  }
}
