@import 'assets/scss/_colors.scss';

$baseR: 17.5px;

.dot {
  border-radius: $baseR;
  min-width: 2 * $baseR;
  height: 2 * $baseR;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  position: relative;
  font-size: 15px;
  font-weight: 300;
  color: $black;
  padding: 0 5px;
}
.container {
  transition: all 0.3s;
  border-radius: $baseR;
  min-width: 2 * $baseR;
  height: 2 * $baseR;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  position: relative;
  font-size: 15px;
  font-weight: 300;
  color: $black;
  padding: 0 5px;

  &.choosen {
    color: $white;
    background-color: $primary-red;
  }
  &.hidden {
    display: none;
  }
  &.lastNumber {
    & + {
      display: initial;
    }
  }
  &.firstNumber {
    & + div {
      display: initial;
    }
  }
  &:hover {
    color: $black;
    background-color: $primary-red-hover;
  }
}

.prefix,
.surfix {
  display: none;
}
