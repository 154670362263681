@import 'assets/scss/_colors.scss';

.container {
  // layout
  transition: box-shadow 0.3s;
  border: none;
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  outline: none;
  width: fit-content;
  min-width: 296px;
  min-height: 47px;

  // button
  &.el-button {
  }
  // a
  &.el-a, &.el-link, &.el-nav-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // FILLED
  &.type-0 {
    color: $white;
    background-color: $primary-red;
    &:hover {
      box-shadow: 0 0 8px $primary-red;
    }
    .mask {
    }

    .content {
      line-height: 22px;
      font-size: 18px;
      font-weight: 300;
      font-family: 'Montserrat', sans-serif;
      user-select: none;
    }
  }

  //OUTLINED
  &.type-1 {
    background-color: $primary-red;
    position: relative;
    &:hover {
      box-shadow: 0 0 8px $primary-red;
      .mask {
        background-color: $primary-red;
      }
      .content {
        color: $white;
      }
    }
    .mask {
      transition: all 0.3s;
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $home-bg-grey;
      margin: 1px;
      border-radius: 7px;
    }

    .content {
      transition: all 0.3s;
      position: relative;
      z-index: 10;
      line-height: 22px;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
      color: $primary-red;
      user-select: none;
    }
  }
}
