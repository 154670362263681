@import 'assets/scss/_colors.scss';

@mixin SVG {
  .svg {
    fill: $primary-blue;
    &.dir-left {
    }
    &.dir-up {
      transform: rotate(90deg);
    }
    &.dir-right {
      transform: rotate(180deg);
    }
    &.dir-down {
      transform: rotate(270deg);
    }
  }
}

.container {
  width: 10px;
  display: inline-block;
  position: relative;

  &.displayNone {
    pointer-events: none;
    opacity: 0;
  }
  > .svg {
    fill: $primary-blue;
    &.dir-left {
    }
    &.dir-up {
      transform: rotate(90deg);
    }
    &.dir-right {
      transform: rotate(180deg);
    }
    &.dir-down {
      transform: rotate(270deg);
    }
  }
  &:hover {
    > svg {
      transition: all 0.3s;
      fill: $primary-blue-hover;
    }
  }
  //mask
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    transform: scale(5, 3);
  }
}
