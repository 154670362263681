@import 'assets/scss/_colors.scss';

.root {
  background: $home-bg-grey;
  font-family: 'Montserrat', sans-serif;
}

.container {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 71px;
  margin-bottom: 222px;
}

.pageTitle {
  line-height: 37px;
  font-size: 30px;
  color: $primary-blue-heavy;
  font-weight: 700;
}

.rulesContent {
  box-sizing: border-box;
  padding: 54px 84px;
  max-width: 1085px;
  width: 100%;
  margin-top: 34px;
  border-radius: 8px;
  background-color: $white;
}
