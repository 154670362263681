@import 'assets/scss/_colors';

.nav-menu {
  display: flex;
  align-items: center;
  .nav-menu-item {
    text-align: center;
    font-size: 1.4rem;
    font-weight: lighter;

    & + .nav-menu-item {
      margin-left: 7.2rem;
    }

    .nav-menu-item-link {
      position: relative;
      color: $white;
      border-style: solid;
      border-color: $white;
      border-width: 0px;
      border-bottom-width: 0px;
      transition: font-weight .6s;
      
      &:before {
        position: absolute;
        bottom: -0.5rem;
        left: 0px;
        right: 0px;
        margin: 0px auto;
        display: block;
        content: '';
        height: .1rem;
        width: 0px;
        opacity: 0;
        background-color: $white;
        transition: width .6s, opacity .6s;
      }

      &:hover, &.nav-menu-item-link-active {
        font-weight: 500;

        &:before {
          opacity: 1;
          width: 100%;
        }
      }

      &:after {
        // 解決 hover，font-weight 改變造成的抖動問題
        display: block;
        content: attr(data-text);
        font-weight: 500;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
}

.nav-menu-same {
  display: flex;
  align-items: center;
  .nav-menu-same-item {
    font-size: 1.4rem;
    font-weight: 500;

    & + .nav-menu-same-item {
      margin-left: 7.2rem;
    }

    .nav-menu-same-item-link {
      color: $white;
    }
  }
}
